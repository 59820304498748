import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test-list',
  templateUrl: './test-list.component.html',
  styleUrls: ['./test-list.component.scss']
})
export class TestListComponent implements OnInit {

  // Sample data to be displayed in the table
  list = [
  { code: 'AQ103', category: 'ALLERGY', test: 'ALLERGY DRUG', price: 4000 },
  { code: '7550', category: 'ALLERGY', test: 'COMPREHENSIVE ALLERGY PROFILE', price: 8000 },
  { code: '8472', category: 'ALLERGY', test: 'ALLERGY DRUG', price: 4000 },
  { code: '4467', category: 'BIOCHEMISTRY', test: '24 HOUR URINARY UREA', price: 300 },
  { code: '22', category: 'BIOCHEMISTRY', test: '24 HOURS URINARY CREATININE', price: 300 },
  { code: '23', category: 'BIOCHEMISTRY', test: '24 HOURS URINARY GLUCOSE', price: 300 },
  { code: 'PQ18', category: 'BIOCHEMISTRY', test: '24 HOURS URINARY PROTEIN', price: 500 },
  { code: '26', category: 'BIOCHEMISTRY', test: '24 HOURS URINARY URIC ACID', price: 300 },
  { code: '27', category: 'BIOCHEMISTRY', test: '24 HOURS URINE CALCIUM', price: 300 },
  { code: '4870', category: 'BIOCHEMISTRY', test: '24 Hr URINARY COPPER', price: 1200 },
  { code: '4979', category: 'BIOCHEMISTRY', test: '24 Hr URINARY MICROALBUMIN', price: 500 },
  { code: 'AQ54', category: 'BIOCHEMISTRY', test: 'ACE (ANGIOTENSIN CONVERTING ENZYME)', price: 800 },
  { code: 'AQ09', category: 'BIOCHEMISTRY', test: 'ACID PHOSHATASE PROSTATIC', price: 500 },
  { code: 'AQ10', category: 'BIOCHEMISTRY', test: 'ACID PHOSPHATASE - TOTAL', price: 500 },
  { code: 'AQ20', category: 'BIOCHEMISTRY', test: 'ACTH (ADRENOCORTICOTROPIC HORMONE)', price: 1200 },
  { code: 'AQ16', category: 'BIOCHEMISTRY', test: 'ADA (ADENOSINE DEAMINASE) ASCITIC FLUID', price: 600 },
  { code: 'AQ13', category: 'BIOCHEMISTRY', test: 'ADENOSINE DEAMINASE (ADA) SERUM', price: 600 },
  { code: 'AQ19', category: 'BIOCHEMISTRY', test: 'ADENOSINE DEAMINASE(ADA) ANY SAMPLE', price: 600 },
  { code: 'AQ17', category: 'BIOCHEMISTRY', test: 'ADENOSINE DEAMINASE(ADA) PERICARDIA FLUID', price: 600 },
  { code: 'AQ18', category: 'BIOCHEMISTRY', test: 'ADENOSINE DEAMINASE(ADA) SYNOVIAL FLUID', price: 600 },
  { code: 'AQ15', category: 'BIOCHEMISTRY', test: 'ADENOSINE DEAMINASE(ADA) PLERAL FLUID', price: 600 },
  { code: 'AQ14', category: 'BIOCHEMISTRY', test: 'ADENOSINE DEAMINASE(ADA)-CSF', price: 500 },
  { code: 'AQ23', category: 'BIOCHEMISTRY', test: 'ALANINE AMINOTRANSFERASE(ALT/SGPT)', price: 150 },
  { code: 'AQ24', category: 'BIOCHEMISTRY', test: 'ALBUMIN', price: 150 },
  { code: '6288', category: 'BIOCHEMISTRY', test: 'ALBUMIN FLUID', price: 150 },
  { code: 'AQ26', category: 'BIOCHEMISTRY', test: 'ALCOHOL SERUM', price: 1200 },
  { code: 'AQ28', category: 'BIOCHEMISTRY', test: 'ALDOLASE', price: 500 },
  { code: 'AQ29', category: 'BIOCHEMISTRY', test: 'ALDOSTERONE', price: 1000 },
  { code: 'AQ30', category: 'BIOCHEMISTRY', test: 'ALKALINE PHOSPHATASE (ALP)', price: 150 },
  { code: 'AQ99', category: 'BIOCHEMISTRY', test: 'ALKALINE PHOSPHATASE (ALP) ISOENZYMES', price: 200 },
  { code: 'AQ38', category: 'BIOCHEMISTRY', test: 'ALPHA-1 ANTI TRYPSIN', price: 1300 },
  { code: '7315', category: 'BIOCHEMISTRY', test: 'AMINO ACID QUALITATIVE SCREEN (URINE)', price: 3000 },
  { code: 'AQ42', category: 'BIOCHEMISTRY', test: 'AMMONIA', price: 1000 },
  { code: 'AQ87', category: 'BIOCHEMISTRY', test: 'ANTI DIURETIC HORMONE (ADH)', price: 1000 },
  { code: 'AQ79', category: 'BIOCHEMISTRY', test: 'APO-A1 (APOLIPOPROTEIN-A1)', price: 500 },
  { code: 'AQ80', category: 'BIOCHEMISTRY', test: 'APO-B (APOLIPOPROTEIN - B)', price: 500 },
  { code: '8346', category: 'BIOCHEMISTRY', test: 'APO-B/APO-A RATIO', price: 800 },
  { code: 'AQ83', category: 'BIOCHEMISTRY', test: 'ASO (ANTI STREPTOLYSIN - O) TITRE', price: 600 },
  { code: 'AQ84', category: 'BIOCHEMISTRY', test: 'ASPARTATE TRANSAMINASE (AST/SGOT)', price: 150 },
  { code: 'BQ10', category: 'BIOCHEMISTRY', test: 'BETA HUMAN CHORIONIC GONODOTROPIN HORMONE (FREE)', price: 1000 },
  { code: 'BQ09', category: 'BIOCHEMISTRY', test: 'BETA HUMAN CHORIONIC GONODOTROPIN HORMONE TITER', price: 500 },
  { code: 'BQ11', category: 'BIOCHEMISTRY', test: 'BICARBONATE', price: 600 },
  { code: 'BQ36', category: 'BIOCHEMISTRY', test: 'BILE ACIDS TOTAL - SERUM', price: 1400 },
  { code: 'BQ12', category: 'BIOCHEMISTRY', test: 'Bilirubin (Total+Direct)', price: 200 },
  { code: 'BQ13', category: 'BIOCHEMISTRY', test: 'BILIRUBIN DIRECT', price: 200 },
  { code: 'BQ13a', category: 'BIOCHEMISTRY', test: 'BILIRUBIN INDIRECT', price: 200 },
  { code: 'BQ14', category: 'BIOCHEMISTRY', test: 'BILIRUBIN TOTAL', price: 150 },

  // New Data
  { code: 'BQ26', category: 'BIOCHEMISTRY', test: 'BLOOD UREA NITROGEN (BUN)', price: 150 },
  { code: 'BQ01', category: 'BIOCHEMISTRY', test: 'BNP (B -TYPE NATRIUTERIC PEPTIDE)', price: 1300 },
  { code: 'AQ46', category: 'BIOCHEMISTRY', test: 'Body fluid Amylase', price: 500 },
  { code: 'BQ45', category: 'BIOCHEMISTRY', test: 'BUN/CREATININE RATIO', price: 200 },
  { code: 'CQ54', category: 'BIOCHEMISTRY', test: 'C-REACTIVE PROTEIN (CRP)', price: 500 },
  { code: 'CQ07', category: 'BIOCHEMISTRY', test: 'CALCIUM', price: 150 },
  { code: 'CQ08', category: 'BIOCHEMISTRY', test: 'CALCIUM-URINE', price: 200 },
  { code: 'CQ18', category: 'BIOCHEMISTRY', test: 'CERULOPLASMIN', price: 1800 },
  { code: 'CQ26', category: 'BIOCHEMISTRY', test: 'CHLORIDE - CSF', price: 200 },
  { code: 'CQ27', category: 'BIOCHEMISTRY', test: 'CHLORIDE - URINE', price: 200 },
  { code: 'CQ25', category: 'BIOCHEMISTRY', test: 'CHLORIDE SERUM', price: 150 },
  { code: 'CQ28', category: 'BIOCHEMISTRY', test: 'CHOLESTEROL-H D L', price: 200 },
  { code: 'CQ29', category: 'BIOCHEMISTRY', test: 'CHOLESTEROL-L D L (DIRECT)', price: 150 },
  { code: '7140', category: 'BIOCHEMISTRY', test: 'Cholestrol Body Fluid', price: 150 },
  { code: 'CQ109', category: 'BIOCHEMISTRY', test: 'CHOLESTROL-VLDL', price: 150 },
  { code: 'CQ41', category: 'BIOCHEMISTRY', test: 'COMPLEMENT 3 (C3)', price: 600 },
  { code: 'CQ42', category: 'BIOCHEMISTRY', test: 'COMPLEMENT 4 (C4 )', price: 600 },
  { code: 'CQ48', category: 'BIOCHEMISTRY', test: 'COPPER', price: 1200 },
  { code: 'CQ49', category: 'BIOCHEMISTRY', test: 'COPPER URINE', price: 1500 },
  { code: '7111', category: 'BIOCHEMISTRY', test: 'COPPER URINE SPOT', price: 1500 },
  { code: '1209', category: 'BIOCHEMISTRY', test: 'CPK - MB', price: 650 },
  { code: '6330', category: 'BIOCHEMISTRY', test: 'CPK-NAC', price: 500 },
  { code: 'CQ55', category: 'BIOCHEMISTRY', test: 'CREATINE KINASE MB (CK-MB)', price: 650 },
  { code: '7423', category: 'BIOCHEMISTRY', test: 'CREATININE & eGFR (CT)', price: 300 },
  { code: 'CQ58', category: 'BIOCHEMISTRY', test: 'CREATININE CLEARANCE TEST', price: 300 },
  { code: 'DQ03', category: 'BIOCHEMISTRY', test: 'D-DIMER', price: 1000 },
  { code: '80098', category: 'BIOCHEMISTRY', test: 'demo9', price: 400 },
  { code: 'GQ11', category: 'BIOCHEMISTRY', test: 'EGFR (ESTIMATED GLOMERULAR FILTRATION RATE)', price: 300 },
  { code: 'AQE1', category: 'BIOCHEMISTRY', test: 'ELECTROLYTE PROFILE', price: 350 },
  { code: '8374', category: 'BIOCHEMISTRY', test: 'FREE LIGHT CHAIN ASSAY (KAPPA & LAMBDA)', price: 3000 },
  { code: 'FQ20', category: 'BIOCHEMISTRY', test: 'FRUCTOSAMINE', price: 600 },
  { code: '7338', category: 'BIOCHEMISTRY', test: 'Galactomannan (Aspergillus Antigen) Serum', price: 2500 },
  { code: 'GQ01', category: 'BIOCHEMISTRY', test: 'GAMMA GLUTAMYL TRANSFERASE (GGT)', price: 200 },
  { code: 'GQ03', category: 'BIOCHEMISTRY', test: 'GLOBULIN', price: 50 },
  { code: 'GQ05', category: 'BIOCHEMISTRY', test: 'GLUCOSE - PP (PP)', price: 80 },
  { code: 'GQ07', category: 'BIOCHEMISTRY', test: 'GLUCOSE 6 PHOSPHATE DEHYDROGENASE (G6PD QUANTITATIVE)', price: 1000 },
  { code: 'GQ22', category: 'BIOCHEMISTRY', test: 'GLUCOSE CHALLENGE TEST', price: 100 },
  { code: '8367', category: 'BIOCHEMISTRY', test: 'GLUCOSE CHALLENGE TEST (GCT50GM)', price: 200 },
  { code: '1916', category: 'BIOCHEMISTRY', test: 'GLUCOSE TOLERANCE TEST- 1ST HOUR', price: 100 },
  { code: '1915', category: 'BIOCHEMISTRY', test: 'GLUCOSE TOLERANCE TEST- 2ND HOUR', price: 100 },
  { code: 'GQ04', category: 'BIOCHEMISTRY', test: 'GLUCOSE- FASTING (FBS)', price: 80 },
  { code: 'GQ06', category: 'BIOCHEMISTRY', test: 'GLUCOSE- RANDOM (RBS)', price: 80 },
  { code: 'GQ09', category: 'BIOCHEMISTRY', test: 'Glycosylated Hemoglobin (GHB/HbA1C)', price: 500 },
  { code: 'IQ02', category: 'BIOCHEMISTRY', test: 'IMMUNOFIXATION ELECTROPHORESIS', price: 2500 },
  { code: 'IQ25', category: 'BIOCHEMISTRY', test: 'INSULIN ANTIBODIES TYPE I & II DIABETES', price: 1800 },
  { code: 'IQ14', category: 'BIOCHEMISTRY', test: 'IONIZED CALCIUM', price: 250 },
  { code: 'IQ12', category: 'BIOCHEMISTRY', test: 'IRON', price: 300 },
  { code: 'IQ13', category: 'BIOCHEMISTRY', test: 'IRON BINDINGCAPACITY-TOTAL(TIBC)', price: 300 },
  { "code": "AQI1", "category": "BIOCHEMISTRY", "test": "IRON DEFICIENCY PROFILE", "price": 500 },
  { "code": "6649", "category": "BIOCHEMISTRY", "test": "Kappa Lambda Free Light Chain Assay", "price": 2200 },
  { "code": "KQ03", "category": "BIOCHEMISTRY", "test": "KETONE BODIES-SERUM", "price": 100 },
  { "code": "8489", "category": "BIOCHEMISTRY", "test": "KFT+ELEC+PHOS", "price": 650 },
  { "code": "8488", "category": "BIOCHEMISTRY", "test": "KFT+ELECTROLYTES", "price": 550 },
  { "code": "AQK1", "category": "BIOCHEMISTRY", "test": "KIDNEY FUNCTION TEST", "price": 500 },
  { "code": "LQ02", "category": "BIOCHEMISTRY", "test": "LACTATE (CSF)", "price": 500 },
  { "code": "LQ07", "category": "BIOCHEMISTRY", "test": "LACTATE DEHYDROGENASE (LDH) - ANY FLUID", "price": 500 },
  { "code": "LQ06", "category": "BIOCHEMISTRY", "test": "LACTATE DEHYDROGENASE (LDH)-ASCITIC FLUID", "price": 500 },
  { "code": "LQ05", "category": "BIOCHEMISTRY", "test": "LACTATE DEHYDROGENASE (LDH)-PLEURAL FLUID", "price": 500 },
  { "code": "LQ04", "category": "BIOCHEMISTRY", "test": "LACTATE DEHYDROGENASE-CSF (LDH)", "price": 500 },
  { "code": "LQ03", "category": "BIOCHEMISTRY", "test": "LACTATE DEHYDROGENASE-SERUM (LDH)", "price": 500 },
  { "code": "2AQL3", "category": "BIOCHEMISTRY", "test": "LDL/ HDL RATIO", "price": 150 },
  { "code": "LQ09", "category": "BIOCHEMISTRY", "test": "LEAD", "price": 1200 },
  { "code": "LQ10", "category": "BIOCHEMISTRY", "test": "LEAD URINE", "price": 1500 },
  { "code": "7466", "category": "BIOCHEMISTRY", "test": "LEAD URINE 24 Hr", "price": 1500 },
  { "code": "7903", "category": "BIOCHEMISTRY", "test": "LEPTIN", "price": 2500 },
  { "code": "8475", "category": "BIOCHEMISTRY", "test": "LFT WITH GGT", "price": 650 },
  { "code": "LQ22", "category": "BIOCHEMISTRY", "test": "LIPASE (ANY FLUID)", "price": 600 },
  { "code": "AQL2", "category": "BIOCHEMISTRY", "test": "LIPID PROFILE", "price": 500 },
  { "code": "LQ17", "category": "BIOCHEMISTRY", "test": "LIPOPROTEIN (LP-a)", "price": 600 },
  { "code": "LQ18", "category": "BIOCHEMISTRY", "test": "LITHIUM", "price": 120 },
  { "code": "AQL1", "category": "BIOCHEMISTRY", "test": "LIVER FUNCTION TEST", "price": 500 },
  { "code": "7637", "category": "BIOCHEMISTRY", "test": "MANGANESE SERUM", "price": 450 },
  { "code": "MQ05", "category": "BIOCHEMISTRY", "test": "MARIJUANA-(URNIE)", "price": 100 },
  { "code": "MQ15", "category": "BIOCHEMISTRY", "test": "MICROALBUMIN", "price": 500 },
  { "code": "MQ07", "category": "BIOCHEMISTRY", "test": "NAIL BIOPSY", "price": 1000 },
  { "code": "8379", "category": "BIOCHEMISTRY", "test": "NCC ELISA", "price": 1500 },
  { "code": "PQ05", "category": "BIOCHEMISTRY", "test": "PHENYTOIN (EPTOIN)", "price": 600 },
  { "code": "PQ06", "category": "BIOCHEMISTRY", "test": "PHOSPHORUS", "price": 300 },
  { "code": "PQ07", "category": "BIOCHEMISTRY", "test": "PHOSPHORUS- URINE", "price": 350 },
  { "code": "8381", "category": "BIOCHEMISTRY", "test": "PIVKA-II", "price": 3500 },
  { "code": "PQ10", "category": "BIOCHEMISTRY", "test": "POTASSIUM SERUM", "price": 300 },
  { "code": "PQ11", "category": "BIOCHEMISTRY", "test": "POTASSIUM-URINE", "price": 350 },
  { "code": "PQ22", "category": "BIOCHEMISTRY", "test": "PROTEIN ELECROPHORESIS", "price": 1000 },
  { "code": "PQ24", "category": "BIOCHEMISTRY", "test": "PROTEIN ELECROPHORESIS (URINE)", "price": 1200 },
  { "code": "PQ23", "category": "BIOCHEMISTRY", "test": "PROTEIN ELECROPHORESIS-CSF", "price": 1200 },
  { "code": "PQ20", "category": "BIOCHEMISTRY", "test": "PROTEIN-FLUID", "price": 300 },
  { "code": "6205", "category": "BIOCHEMISTRY", "test": "RANDOM URINE CALCIUM/ CREATININE RATIO", "price": 500 },
  { "code": "RQ02", "category": "BIOCHEMISTRY", "test": "RBC FOLATE", "price": 500 },
  { "code": "3514", "category": "BIOCHEMISTRY", "test": "RENAL FUNCTION TEST 1", "price": 500 },
  { "code": "RQ08", "category": "BIOCHEMISTRY", "test": "RHEUMATOID FACTOR (RA TEST)", "price": 500 },
  { "code": "7443", "category": "BIOCHEMISTRY", "test": "SELENIUM - URINE 24 Hr", "price": 1700 },
  { "code": "SQ13", "category": "BIOCHEMISTRY", "test": "SELENIUM ICP", "price": 3500 },
  { "code": "SQ14", "category": "BIOCHEMISTRY", "test": "SELENIUM ICP- URINE", "price": 1700 },
  { "code": "AQ44", "category": "BIOCHEMISTRY", "test": "SERUM AMYLASE", "price": 600 },
  { "code": "CQ56", "category": "BIOCHEMISTRY", "test": "SERUM CREATININE", "price": 150 },
  { "code": "3657", "category": "BIOCHEMISTRY", "test": "SERUM INORGANIC PHOSPHORUS", "price": 300 },
  { "code": "LQ16", "category": "BIOCHEMISTRY", "test": "SERUM LIPASE", "price": 600 },
  { "code": "SQ08", "category": "BIOCHEMISTRY", "test": "SODIUM -URINE", "price": 300 },
  { "code": "SQ07", "category": "BIOCHEMISTRY", "test": "SODIUM SERUM", "price": 250 },
  { "code": "PQ19", "category": "BIOCHEMISTRY", "test": "SPOT URINARY PROTEIN", "price": 500 },
  { "code": "3769", "category": "BIOCHEMISTRY", "test": "SPOT URINE FOR MICROPROTEIN", "price": 500 },
  { "code": "SQ10", "category": "BIOCHEMISTRY", "test": "STONE ANALYSIS", "price": 800 },
  { "code": "3983", "category": "BIOCHEMISTRY", "test": "Total Bilrubin", "price": 150 },
  { "code": "CQ30", "category": "BIOCHEMISTRY", "test": "TOTAL CHOLESTEROL", "price": 150 },
  { "code": "AQL2", "category": "BIOCHEMISTRY", "test": "TOTAL CHOLESTEROL / HDL RATIO", "price": 300 },
  { "code": "PQ26", "category": "BIOCHEMISTRY", "test": "TOTAL PROTEIN", "price": 150 },
  { "code": "TQ19", "category": "BIOCHEMISTRY", "test": "TRANSFERRIN", "price": 350 },
  { "code": "TQ36", "category": "BIOCHEMISTRY", "test": "TRANSFERRIN  %", "price": 400 },
  { "code": "TQ21", "category": "BIOCHEMISTRY", "test": "TRIGLYCERIDES (TGL)", "price": 150 },
  { "code": "7713", "category": "BIOCHEMISTRY", "test": "UNSATURATED IRON BINDING CAPACITY (UIBC)", "price": 300 },
  { "code": "UQ01", "category": "BIOCHEMISTRY", "test": "UREA", "price": 150 },
  { "code": "UQ03", "category": "BIOCHEMISTRY", "test": "URIC ACID", "price": 150 },
  { "code": "5480", "category": "BIOCHEMISTRY", "test": "URINARY CALCIUM CREATNINE RATIO", "price": 500 },
  { "code": "UQ05", "category": "BIOCHEMISTRY", "test": "Urinary Cortisol/Creatinine Ratio", "price": 1000 },
  { "code": "CQ57", "category": "BIOCHEMISTRY", "test": "URINARY CREATININE", "price": 150 },
  { "code": "UQ02", "category": "BIOCHEMISTRY", "test": "URINARY UREA", "price": 150 },
  { "code": "UQO4", "category": "BIOCHEMISTRY", "test": "URINARY URIC ACID", "price": 150 },
  { "code": "MQ15", "category": "BIOCHEMISTRY", "test": "URINE ALBUMIN/CREATININE RATIO", "price": 100 },
  { "code": "AQ45", "category": "BIOCHEMISTRY", "test": "URINE AMYLASE", "price": 600 },
  { "code": "AQE2", "category": "BIOCHEMISTRY", "test": "URINE ELECTROLYTES", "price": 500 },
  { "code": "4680", "category": "BIOCHEMISTRY", "test": "URINE FOR TRIGLYCERIDES", "price": 150 },
  { "code": "PQ28", "category": "BIOCHEMISTRY", "test": "URINE PROTEIN CREATININE RATIO", "price": 500 },
  { "code": "4244", "category": "BIOCHEMISTRY", "test": "VALPROIC ACID", "price": 1000 },
  { "code": "VQ12", "category": "BIOCHEMISTRY", "test": "VITAMIN - E", "price": 2500 },
  { "code": "VQ13", "category": "BIOCHEMISTRY", "test": "VITAMIN - K1", "price": 3000 },
  { "code": "ZQ04", "category": "BIOCHEMISTRY", "test": "ZINC", "price": 1200 },
  { "code": "ZQ03", "category": "BIOCHEMISTRY", "test": "ZINC URINE", "price": 1500 },
  { "code": "6554", "category": "CLINICAL PATHOLOGY", "test": "24 Hour Urine Oxalate", "price": 500 },
  { "code": "AQ25", "category": "CLINICAL PATHOLOGY", "test": "ALBUMIN / CREATININE RATIO", "price": 200 },
  { "code": "BQ05", "category": "CLINICAL PATHOLOGY", "test": "BENCE JONES PROTEIN - URINE SPOT", "price": 350 },
  { "code": "BQ45", "category": "CLINICAL PATHOLOGY", "test": "Bile Salt and Bile Pigment", "price": 150 },
  { "code": "5235", "category": "CLINICAL PATHOLOGY", "test": "CA 72.4", "price": 1800 },
  { "code": "CQ45", "category": "CLINICAL PATHOLOGY", "test": "Complete Urine Examination (CUE)", "price": 200 },
  { "code": "4559", "category": "CLINICAL PATHOLOGY", "test": "DRUG ABUSE TEST ELISA (URINE) 6 TEST", "price": 1500 },
  { "code": "DQ13", "category": "CLINICAL PATHOLOGY", "test": "DRUGS OF ABUSE PANEL ELISA - 12 DRUGS", "price": 3000 },
  { "code": "8400", "category": "CLINICAL PATHOLOGY", "test": "FILARIA SEROLOGY", "price": 600 },
  { "code": "8466", "category": "CLINICAL PATHOLOGY", "test": "FLUID FOR AFB", "price": 700 },
  { "code": "5869", "category": "CLINICAL PATHOLOGY", "test": "HE4 ROMA INDEX", "price": 1500 },
  { "code": "KQ04", "category": "CLINICAL PATHOLOGY", "test": "KETONE BODIES-URINE", "price": 300 },
  { "code": "8451", "category": "CLINICAL PATHOLOGY", "test": "RHEUMATOID FACTOR (QUALITATIVE)", "price": 350 },
  { "code": "SQ03", "category": "CLINICAL PATHOLOGY", "test": "SEMEN ANALYSIS", "price": 300 },
  { "code": "3652", "category": "CLINICAL PATHOLOGY", "test": "SEMEN FRUCTOSE LEVEL", "price": 650 },
  { "code": "8377", "category": "CLINICAL PATHOLOGY", "test": "SPOT URINE MICROALBUMIN", "price": 500 },
  { "code": "SQ11", "category": "CLINICAL PATHOLOGY", "test": "STOOL EXAMINATION", "price": 200 },
  { "code": "SQ12", "category": "CLINICAL PATHOLOGY", "test": "STOOL OCCULT BLOOD", "price": 300 },
  { "code": "SQ0", "category": "CLINICAL PATHOLOGY", "test": "URINE FOR OCCULT BLOOD", "price": 300 },
  { "code": "4171", "category": "CLINICAL PATHOLOGY", "test": "URINE FOR SUGAR - FASTING", "price": 100 },
  { "code": "4179", "category": "CLINICAL PATHOLOGY", "test": "URINE pH", "price": 100 },
  { "code": "PQ12", "category": "CLINICAL PATHOLOGY", "test": "URINE PREGNANCY TEST", "price": 100 },
  { "code": "4213", "category": "CLINICAL PATHOLOGY", "test": "URINE SUGAR", "price": 80 },
  { "code": "4214", "category": "CLINICAL PATHOLOGY", "test": "URINE SUGAR - PP", "price": 80 },
  { "code": "CQ37", "category": "CYTOGENETICS", "test": "CHROMOSOME ANALYSIS", "price": 3500 },
  { "code": "HQ04", "category": "CYTOGENETICS", "test": "HER - 2/neu STUDY (ERPR & HER2)", "price": 8000 },
  { "code": "6659", "category": "CYTOGENETICS", "test": "PERIPHERAL BLOOD FOR HIGH RESOLUTION KARYOTYPING (SINGLE)", "price": 4000 },
  { "code": "6640", "category": "CYTOGENETICS", "test": "PERIPHERAL BLOOD FOR KARYOTYPING (COUPLE) MALE", "price": 4000 },
  { "code": "CQ77", "category": "CYTOPATHOLOGY", "test": "CYTOLOGY FLUID", "price": 1000 },
  { "code": "CQ78", "category": "CYTOPATHOLOGY", "test": "CYTOLOGY FNAC", "price": 600 },
  { "code": "CQ92", "category": "CYTOPATHOLOGY", "test": "CYTOLOGY LBC", "price": 600 },
  { "code": "FQ13", "category": "CYTOPATHOLOGY", "test": "FLUID EXAMINATION ROUTINE", "price": 1000 },
  { "code": "4613", "category": "CYTOPATHOLOGY", "test": "LIQUID BASED CYTOLOGY - PAP SMEAR", "price": 800 },
  { "code": "32280", "category": "CYTOPATHOLOGY", "test": "PAP SMEAR", "price": 600 },
  { "code": "3741", "category": "CYTOPATHOLOGY", "test": "SMEAR FOR MALIGNANT CELLS", "price": 200 },
  { "code": "SQ09", "category": "CYTOPATHOLOGY", "test": "SPUTUM FOR MALIGNANT CELLS", "price": 200 },
  { "code": "4165", "category": "CYTOPATHOLOGY", "test": "URINE FOR CYTOLOGY", "price": 200 },
  { "code": "CQ16", "category": "FLOW CYTOMETRY", "test": "CD3/CD4/CD8", "price": 1500 },
  { "code": "CQ17", "category": "FLOW CYTOMETRY", "test": "CD4 ABSOLUTE COUNT", "price": 1500 },
  { "code": "AQ11", "category": "HAEMATOLOGY", "test": "APTT (ACTIVATED PARTIAL THROMBOPLASTIN TIME)", "price": 550 },
  { "code": "BQ23", "category": "HAEMATOLOGY", "test": "BLEEDING TIME", "price": 150 },
  { "code": "BQ24", "category": "HAEMATOLOGY", "test": "BLOOD GROUP (ABO) & RH TYPE", "price": 150 },
  { "code": "CQ40", "category": "HAEMATOLOGY", "test": "CLOTTING TIME", "price": 150 },
  { "code": "CBC", "category": "HAEMATOLOGY", "test": "Complete blood count with Esr (CBC)", "price": 400 },
  { "code": "CQ46", "category": "HAEMATOLOGY", "test": "Coombs-Direct (DCT)", "price": 600 },
  { "code": "6646", "category": "HAEMATOLOGY", "test": "FILARIA ANTIBODY DETECTION", "price": 350 },
  { "code": "1752", "category": "HAEMATOLOGY", "test": "FILARIA ANTIGEN", "price": 300 },
  { "code": "HQ08", "category": "HAEMATOLOGY", "test": "HEMOGLOBIN (HB)", "price": 150 },
  { "code": "HQ09", "category": "HAEMATOLOGY", "test": "HEMOGLOBINOPATHY BY HPLC(Hb Electrophoresis)", "price": 1000 },
  { "code": "AQ44", "category": "HAEMATOLOGY", "test": "Hemogram", "price": 500 },
  { "code": "LQ19", "category": "HAEMATOLOGY", "test": "LUPUS ANTICOAGULANT", "price": 1200 },
  { "code": "2741", "category": "HAEMATOLOGY", "test": "MALARIA ANTIGEN", "price": 500 },
  { "code": "MQ03", "category": "HAEMATOLOGY", "test": "MALARIAL PARASITE IDENTIFICATION", "price": 150 },
  { "code": "2732", "category": "HAEMATOLOGY", "test": "MCHC", "price": 100 },
  { "code": "2731", "category": "HAEMATOLOGY", "test": "Mean Cell Haemoglobin (MCH)", "price": 100 },
  { "code": "PQ03", "category": "HAEMATOLOGY", "test": "Packed cell Volume (HCT)", "price": 100 },
  { "code": "BQ25", "category": "HAEMATOLOGY", "test": "PERIPHERAL SMEAR EXAMINATION (PBF)", "price": 200 },
  { "code": "PQ08", "category": "HAEMATOLOGY", "test": "PLATELET COUNT", "price": 150 },
  { "code": "PQ29", "category": "HAEMATOLOGY", "test": "PROTHROMBIN TIME(PT)", "price": 350 },
  { "code": "4609", "category": "HAEMATOLOGY", "test": "RDW", "price": 100 },
  { "code": "6273", "category": "HAEMATOLOGY", "test": "RETICULOCYTE COUNT", "price": 150 },
  { "code": "TQ24", "category": "HAEMATOLOGY", "test": "TROPONIN - I", "price": 1400 },
  { "code": "TQ25", "category": "HAEMATOLOGY", "test": "Troponin - T", "price": 1200 },
  { "code": "BQ17", "category": "HISTOPATHOLOGY", "test": "BIOPSY- LARGE SPECIMEN (>5CM)", "price": 1800 },
  { "code": "BQ19", "category": "HISTOPATHOLOGY", "test": "BIOPSY- SMALL SPECIMEN (<2CM)", "price": 800 },
  { "code": "BQ15", "category": "HISTOPATHOLOGY", "test": "BIOPSY-BONE", "price": 1200 },
  { "code": "BQ16", "category": "HISTOPATHOLOGY", "test": "BIOPSY-EXTRA LARGE SPECIMEN", "price": 1500 },
  { "code": "BQ18", "category": "HISTOPATHOLOGY", "test": "BIOPSY-MEDIUM SPECIMEN(UPTO 5CM)", "price": 1000 },
  { "code": "BQ20", "category": "HISTOPATHOLOGY", "test": "BIOPSY-UTERUS WITH BOTH TUBES&OVARIES", "price": 1800 },
  { "code": "BQ21", "category": "HISTOPATHOLOGY", "test": "BIOPSY-UTERUS WITH ONE TUBE &OVARY", "price": 1500 },
  { "code": "BQ22", "category": "HISTOPATHOLOGY", "test": "BIOPSY-UTERUS WITHOUT TUBES&OVARIES", "price": 1800 },
  { "code": "28", "category": "HORMONE ASSAYS", "test": "24 HR URINARY CORTISOL", "price": 700 },
  { "code": "HQ02", "category": "HORMONE ASSAYS", "test": "25-Hydroxy Vitamin D3 Total", "price": 800 },
  { "code": "4649", "category": "HORMONE ASSAYS", "test": "5-Alpha Dihydrotestostrone (DHT)", "price": 3500 },
  { "code": "7847", "category": "HORMONE ASSAYS", "test": "ACTH STIMULATION TEST FOR 17-OH PROGESTERONE", "price": 1000 },
  { "code": "AQ36", "category": "HORMONE ASSAYS", "test": "AFP (ALFA-FETO PROTEIN)", "price": 1000 },
  { "code": "8512", "category": "HORMONE ASSAYS", "test": "ALPHA FETO PROTEIN TUMOR MARKER", "price": 1000 },
  { "code": "AQ53", "category": "HORMONE ASSAYS", "test": "ANDROSTEINODIONE (A4)", "price": 850 },
  { "code": "AQ61", "category": "HORMONE ASSAYS", "test": "ANTI MULLERIAN HORMONE (AMH)", "price": 1500 },
  { "code": "AQ59", "category": "HORMONE ASSAYS", "test": "Anti TPO/THYROPEROXIDASE(AMA)", "price": 1000 },
  { "code": "AQ78", "category": "HORMONE ASSAYS", "test": "ATG (ANTI THYROGLOBULIN ANTIBODY)", "price": 1000 },
  { "code": "BQ06", "category": "HORMONE ASSAYS", "test": "BETA 2 MICROGLOBULIN", "price": 1200 },
  { "code": "BQ07", "category": "HORMONE ASSAYS", "test": "BETA HCG", "price": 550 },
  { "code": "CQ53F", "category": "HORMONE ASSAYS", "test": "C-PEPITIDE - FASTING", "price": 1000 },
  { "code": "6286", "category": "HORMONE ASSAYS", "test": "C-PEPITIDE PP", "price": 1000 },
  { "code": "CQ53", "category": "HORMONE ASSAYS", "test": "C-PEPTIDE", "price": 1000 },
  { "code": "764", "category": "HORMONE ASSAYS", "test": "CA - 125", "price": 1000 },
  { "code": "772", "category": "HORMONE ASSAYS", "test": "CA 15.3", "price": 1000 },
  { "code": "780", "category": "HORMONE ASSAYS", "test": "CA 19.9", "price": 1000 },
  { "code": "CQ01", "category": "HORMONE ASSAYS", "test": "CA125-OVARIAN CANCER MARKER", "price": 1200 },
  { "code": "CQ02", "category": "HORMONE ASSAYS", "test": "CA15.3-BREAST CANCER MARKER", "price": 1200 },
  { "code": "CQ03", "category": "HORMONE ASSAYS", "test": "CAl9.9-PANCREATIC CANCER MARKER", "price": 1200 },
  { "code": "CQ06", "category": "HORMONE ASSAYS", "test": "CALCITONIN", "price": 1800 },
  { "code": "CQ10", "category": "HORMONE ASSAYS", "test": "CARCINO EMBRYONIC ANTIGEN (CEA)", "price": 1000 },
  { "code": "7128", "category": "HORMONE ASSAYS", "test": "CORTISOL RANDOM SAMPLE", "price": 600 },
  { "code": "CQ51", "category": "HORMONE ASSAYS", "test": "Cortisol Serum", "price": 600 },
  { "code": "CQ50", "category": "HORMONE ASSAYS", "test": "Cortisol Serum (3 TO 5 PM)", "price": 600 },
  { "code": "CQ52", "category": "HORMONE ASSAYS", "test": "CORTISOL URINE TOTAL", "price": 600 },
  { "code": "DQ04", "category": "HORMONE ASSAYS", "test": "DHEA", "price": 900 },
  { "code": "DQ05", "category": "HORMONE ASSAYS", "test": "DHEA - SULPHATE", "price": 1200 },
  { "code": "8413", "category": "HORMONE ASSAYS", "test": "DIHYDROTESTOSTERONE", "price": 2200 },
  { "code": "AQD4", "category": "HORMONE ASSAYS", "test": "Dual Marker with graph", "price": 2200 },
  { "code": "EQ14", "category": "HORMONE ASSAYS", "test": "ESTRADIOL (E2)", "price": 650 },
  { "code": "EQ15", "category": "HORMONE ASSAYS", "test": "ESTRIOL-UNCONJUGATED (E3)", "price": 1000 },
  { "code": "FQ05", "category": "HORMONE ASSAYS", "test": "FERRITIN", "price": 600 },
  { "code": "FQ14", "category": "HORMONE ASSAYS", "test": "FOLATE SERUM (FOLIC ACID)", "price": 600 },
  { "code": "FQ15", "category": "HORMONE ASSAYS", "test": "FOLLICLE STIMULATING HORMONE (FSH)", "price": 500 },
  { "code": "8528F", "category": "HORMONE ASSAYS", "test": "FREE THYROID PROFILE (FTFT)", "price": 650 },
  { "code": "GQ13", "category": "HORMONE ASSAYS", "test": "GROWTH HORMONE (HGH)", "price": 650 },
  { "code": "HQ57", "category": "HORMONE ASSAYS", "test": "HOMA -IR; INSULIN RESISTANCE", "price": 1500 },
  { "code": "HQ41", "category": "HORMONE ASSAYS", "test": "HOMOCYSTEINE", "price": 1400 },
  { "code": "IQ08", "category": "HORMONE ASSAYS", "test": "INSULIN - FASTING", "price": 500 },
  { "code": "IQ09", "category": "HORMONE ASSAYS", "test": "INSULIN - POSTPRANDIAL", "price": 500 },
  { "code": "5479", "category": "HORMONE ASSAYS", "test": "INSULIN RANDOM", "price": 500 },
  { "code": "IQ11", "category": "HORMONE ASSAYS", "test": "Intact Parathyroid Hormone (IPTH)", "price": 1000 },
  { "code": "IQ17", "category": "HORMONE ASSAYS", "test": "INTERLEUKIN 6", "price": 2200 },
  { "code": "LQ20", "category": "HORMONE ASSAYS", "test": "LUTEINISING HORMONE (LH)", "price": 500 },
  { "code": "5414", "category": "HORMONE ASSAYS", "test": "NT PRO BNP (N- TERMINAL PRO B TYPE NATRIURETIC PEPTIDE)", "price": 2500 },
  { "code": "PQ02", "category": "HORMONE ASSAYS", "test": "PAPP-A", "price": 1000 },
  { "code": "PQ13", "category": "HORMONE ASSAYS", "test": "PROCALCITONIN", "price": 1800 },
  { "code": "PQ14", "category": "HORMONE ASSAYS", "test": "PROGESTERONE", "price": 650 },
  { "code": "PQ15", "category": "HORMONE ASSAYS", "test": "PROLACTIN", "price": 500 },
  { "code": "7376", "category": "HORMONE ASSAYS", "test": "PROLACTIN POOLED SAMPLE", "price": 1200 },
  { "code": "PQ17", "category": "HORMONE ASSAYS", "test": "PROSTATE SPECIFIC ANTIGEN (PSA) - TOTAL", "price": 500 },
  { "code": "PQ16", "category": "HORMONE ASSAYS", "test": "PROSTATE SPECIFIC ANTIGEN (PSA) FREE", "price": 1000 },
  { "code": "AQQ1", "category": "HORMONE ASSAYS", "test": "QUADRUPLE MARKER WITH GRAPH", "price": 3000 },
  { "code": "SQ04", "category": "HORMONE ASSAYS", "test": "SEX HORMONE BINDING GLOBULIN (SHBG)", "price": 600 },
  { "code": "TQ23", "category": "HORMONE ASSAYS", "test": "T3 - TRI-IODOTHYRONINE TOTAL", "price": 300 },
  { "code": "TQ08", "category": "HORMONE ASSAYS", "test": "T4 - THYROXINE", "price": 300 },
  { "code": "AQ02", "category": "HORMONE ASSAYS", "test": "TESTOSTERONE FREE", "price": 1000 },
  { "code": "TQ03", "category": "HORMONE ASSAYS", "test": "TESTOSTERONE TOTAL", "price": 500 },
  { "code": "AQT4", "category": "HORMONE ASSAYS", "test": "THYROID PROFILE I (T3 T4 TSH)", "price": 500 },
  { "code": "AQ07", "category": "HORMONE ASSAYS", "test": "THYROXINE-FREE (FT4)", "price": 350 },
  { "code": "TQ22", "category": "HORMONE ASSAYS", "test": "TRI-IODOTHYRONINE FREE (FT3)", "price": 350 },
  { "code": "AQT16", "category": "HORMONE ASSAYS", "test": "TRIPLE MARKER WITH GRAPH", "price": 2200 },
  { "code": "6304", "category": "HORMONE ASSAYS", "test": "TSH - ULTRASENSITIVE", "price": 300 },
  { "code": "TQ32", "category": "HORMONE ASSAYS", "test": "TSH RECEPTOR ANTIBODIES", "price": 2500 },
  { "code": "TQ06", "category": "HORMONE ASSAYS", "test": "TSH- THYROID STIMULATING HORMONE", "price": 300 },
  { "code": "AQ35", "category": "IMMUNOLOGY - SEROLOGY", "test": "ALLERGY PANEL WITH DRUGS", "price": 3000 },
  { "code": "AQ34", "category": "IMMUNOLOGY - SEROLOGY", "test": "ALLERGY PROFILE INHALENS", "price": 3000 },
  { "code": "AQ33", "category": "IMMUNOLOGY - SEROLOGY", "test": "ALLERGY PROFILE-FOOD (VEG & NON-VEG)", "price": 4000 },
  { "code": "AQ31", "category": "IMMUNOLOGY - SEROLOGY", "test": "ALLERGY PROFILE -FOOD (VEG ONLY)", "price": 3000 },
  { "code": "7473", "category": "IMMUNOLOGY - SEROLOGY", "test": "ALPHA FETO PROTEIN (CSF)", "price": 10200 },
  { "code": "7615", "category": "IMMUNOLOGY - SEROLOGY", "test": "AMA [ANTI MITOCHONDRIAL ANTIBODIES] by IFA", "price": 1200 },
  { "code": "1028", "category": "IMMUNOLOGY - SEROLOGY", "test": "ANA", "price": 500 },
  { "code": "AQA7", "category": "IMMUNOLOGY - SEROLOGY", "test": "ANA PROFILE", "price": 5000 },
  { "code": "AQ51", "category": "IMMUNOLOGY - SEROLOGY", "test": "ANCA - Myeloperoxidase (p-ANCA)", "price": 900 },
  { "code": "AQ52", "category": "IMMUNOLOGY - SEROLOGY", "test": "ANCA- Serine Proteinase 3 (c-ANCA)", "price": 900 },
  { "code": "AQ55", "category": "IMMUNOLOGY - SEROLOGY", "test": "ANTI - CCP (ANTI - CYCLIC CITRULLINATED PEPTIDE)", "price": 1000 },
  { "code": "381", "category": "IMMUNOLOGY - SEROLOGY", "test": "ANTI CARDIOLIPIN ANTIBODY - IgM & IgG", "price": 2200 },
  { "code": "AQ56", "category": "IMMUNOLOGY - SEROLOGY", "test": "ANTI ds DNA ANTIBODY IFA", "price": 1500 },
  { "code": "AQ57", "category": "IMMUNOLOGY - SEROLOGY", "test": "ANTI dsDNA ANTIBODY", "price": 650 },
  { "code": "AQ65", "category": "IMMUNOLOGY - SEROLOGY", "test": "ANTI JO-l ANTIBODY", "price": 800 },
  { "code": "AQ62", "category": "IMMUNOLOGY - SEROLOGY", "test": "ANTI MITOCHONDRIAL ANTIBODIES (AMA) IFA", "price": 1500 },
  { "code": "AQ60", "category": "IMMUNOLOGY - SEROLOGY", "test": "ANTI MITOCHONDRIAL ANTIBODY (AMA)", "price": 1000 },
  { "code": "7872", "category": "IMMUNOLOGY - SEROLOGY", "test": "ANTI MOG (MYELIN OLIGODENDROCYTE GLYCOPROTEIN) SERUM", "price": 6000 },
  { "code": "4730", "category": "IMMUNOLOGY - SEROLOGY", "test": "Anti Nuclear Abs-IFA Hep2 Serum (ANA IFA)", "price": 1500 },
  { "code": "AQ68", "category": "IMMUNOLOGY - SEROLOGY", "test": "ANTI NUCLEAR ANTIBODY(ANA)", "price": 500 },
  { "code": "1021", "category": "IMMUNOLOGY - SEROLOGY", "test": "ANTI PHOSPHOLIPID - IgA (APL IgA)", "price": 900 },
  { "code": "AQ71", "category": "IMMUNOLOGY - SEROLOGY", "test": "ANTI PHOSPHOLIPID - IgG (APL IgG)", "price": 900 },
  { "code": "AQ72", "category": "IMMUNOLOGY - SEROLOGY", "test": "ANTI PHOSPHOLIPID - IgM (APL IgM)", "price": 900 },
  { "code": "4599", "category": "IMMUNOLOGY - SEROLOGY", "test": "ANTI PHOSPHOLIPID SYNDROME PANEL", "price": 1500 },
  { "code": "7551", "category": "IMMUNOLOGY - SEROLOGY", "test": "ANTI PHOSPHOLPID ANTIBODY - IgA (APL - IgA)", "price": 1600 },
  { "code": "AQ73", "category": "IMMUNOLOGY - SEROLOGY", "test": "ANTI SM- ANTIBODY", "price": 1200 },
  { "code": "AQ74", "category": "IMMUNOLOGY - SEROLOGY", "test": "ANTI SMOOTH MUSCLE ANTIBODY - TITRES (ASMA)", "price": 1200 },
  { "code": "AQ63", "category": "IMMUNOLOGY - SEROLOGY", "test": "ANTI SS-A ANTIBODY", "price": 1200 },
  { "code": "439", "category": "IMMUNOLOGY - SEROLOGY", "test": "ANTI TPO / MICROSOMAL ANTIBODY", "price": 1000 },
  { "code": "421", "category": "IMMUNOLOGY - SEROLOGY", "test": "ANTIPHOSPHOLIPID - IgM & IgG (APL IgM & IgG)", "price": 1800 },
  { "code": "AQ93", "category": "IMMUNOLOGY - SEROLOGY", "test": "ASPERGILLUS - IgG ANTIBODY", "price": 1500 },
  { "code": "AQ94", "category": "IMMUNOLOGY - SEROLOGY", "test": "ASPERGILLUS - IgM ANTIBODY", "price": 1500 },
  { "code": "AQ92", "category": "IMMUNOLOGY - SEROLOGY", "test": "ASPERGILLUS FUMIGATUS SPECIFIC - IgE", "price": 1500 },
  { "code": "7891", "category": "IMMUNOLOGY - SEROLOGY", "test": "ASPERGILLUS FUMIGATUS SPECIFIC IgG", "price": 1500 },
  { "code": "7577", "category": "IMMUNOLOGY - SEROLOGY", "test": "BETA 2 GLYCOPROTEIN - IgA ANTIBODY", "price": 1200 },
  { "code": "BQ35", "category": "IMMUNOLOGY - SEROLOGY", "test": "BETA 2 GLYCOPROTEIN ANTIBODY - IgG", "price": 1200 },
  { "code": "BQ34", "category": "IMMUNOLOGY - SEROLOGY", "test": "BETA 2 GLYCOPROTEIN ANTIBODY - IgM", "price": 1200 },
  { "code": "BQ30", "category": "IMMUNOLOGY - SEROLOGY", "test": "BRUCELLA IgG ANTIBODIES", "price": 1500 },
  { "code": "BQ31", "category": "IMMUNOLOGY - SEROLOGY", "test": "BRUCELLA IgM ANTIBODIES", "price": 1500 },
  { "code": "CQ93", "category": "IMMUNOLOGY - SEROLOGY", "test": "CALPROTECTIN FECAL (STOOL)", "price": 2200 },
  { "code": "CQ11", "category": "IMMUNOLOGY - SEROLOGY", "test": "CARDIOLIPIN IgA ANTIBODY", "price": 1200 },
  { "code": "CQ12", "category": "IMMUNOLOGY - SEROLOGY", "test": "CARDIOLIPIN IgG ANTIBODY", "price": 1200 },
  { "code": "CQ13", "category": "IMMUNOLOGY - SEROLOGY", "test": "CARDIOLIPIN IgM ANTIBODY", "price": 1200 },
  { "code": "CQ21", "category": "IMMUNOLOGY - SEROLOGY", "test": "CHIKUNGUNYA (Rapid)", "price": 900 },
  { "code": "CQ20", "category": "IMMUNOLOGY - SEROLOGY", "test": "Chikungunya IgG", "price": 900 },
  { "code": "CQ88", "category": "IMMUNOLOGY - SEROLOGY", "test": "Chikungunya IgM", "price": 900 },
  { "code": "CQ82", "category": "IMMUNOLOGY - SEROLOGY", "test": "CMV-IgG", "price": 500 },
  { "code": "CQ83", "category": "IMMUNOLOGY - SEROLOGY", "test": "CMV-IgM", "price": 500 },
  { "code": "1589", "category": "IMMUNOLOGY - SEROLOGY", "test": "CRP- Qualitative", "price": 350 },
  { "code": "DQ06", "category": "IMMUNOLOGY - SEROLOGY", "test": "Dengue IgG", "price": 800 },
  { "code": "8098", "category": "IMMUNOLOGY - SEROLOGY", "test": "DENGUE IgG (Rapid)", "price": 600 },
  { "code": "DQ07", "category": "IMMUNOLOGY - SEROLOGY", "test": "Dengue IgM", "price": 800 },
  { "code": "8097", "category": "IMMUNOLOGY - SEROLOGY", "test": "DENGUE IgM (Rapid)", "price": 600 },
  { "code": "DQ15R", "category": "IMMUNOLOGY - SEROLOGY", "test": "Dengue NS1 Antigen (Rapid)", "price": 600 },
  { "code": "DQ15", "category": "IMMUNOLOGY - SEROLOGY", "test": "Dengue NS1 Elisa", "price": 1000 },
  { "code": "8507", "category": "IMMUNOLOGY - SEROLOGY", "test": "DENGUE SEROLOGY IGG (ELISA)", "price": 900 },
  { "code": "8508", "category": "IMMUNOLOGY - SEROLOGY", "test": "DENGUE SEROLOGY IGM (ELISA)", "price": 900 },
  { "code": "8417", "category": "IMMUNOLOGY - SEROLOGY", "test": "ENA PROFILE", "price": 5000 },
  { "code": "TQ29", "category": "IMMUNOLOGY - SEROLOGY", "test": "FLUORESCENT TREPONEMAL ANTIBODIES (FTA-ABS)", "price": 3000 },
  { "code": "BQ10", "category": "IMMUNOLOGY - SEROLOGY", "test": "FREE BETA HCG", "price": 1000 },
  { "code": "HQ58", "category": "IMMUNOLOGY - SEROLOGY", "test": "H. PYLORI ANTIGEN", "price": 900 },
  { "code": "4569", "category": "IMMUNOLOGY - SEROLOGY", "test": "HCV IgG", "price": 900 },
  { "code": "8406", "category": "IMMUNOLOGY - SEROLOGY", "test": "HCV IGM", "price": 900 },
  { "code": "HQ06", "category": "IMMUNOLOGY - SEROLOGY", "test": "HELICOBACTER PYLORI IgM", "price": 900 },
  { "code": "HQ47", "category": "IMMUNOLOGY - SEROLOGY", "test": "HELICOBACTER PYLORI - IgA", "price": 900 },
  { "code": "HQ05", "category": "IMMUNOLOGY - SEROLOGY", "test": "HELICOBACTER PYLORI IgG", "price": 900 },
  { "code": "HQ11", "category": "IMMUNOLOGY - SEROLOGY", "test": "HEPATITIS A VIRUS (HAV) TOTAL ANTIBODY", "price": 1000 },
  { "code": "8365", "category": "IMMUNOLOGY - SEROLOGY", "test": "HEPATITIS A VIRUS IGG (ANTI HAV)", "price": 1000 },
  { "code": "HQ10", "category": "IMMUNOLOGY - SEROLOGY", "test": "HEPATITIS A VIRUS- IgM (HAV-IgM)", "price": 1000 },
  { "code": "HQ16", "category": "IMMUNOLOGY - SEROLOGY", "test": "HEPATITIS B SURFACE ANTIGEN (HBSAg)- ELISA", "price": 1000 },
  { "code": "HQ48", "category": "IMMUNOLOGY - SEROLOGY", "test": "HEPATITIS B SURFACE ANTIGEN (HBsAg/RAPID)", "price": 500 },
  { "code": "HQ12", "category": "IMMUNOLOGY - SEROLOGY", "test": "HEPATITIS B ENVELOPE ANTIBODY (ANTI - HBe)", "price": 700 },
  { "code": "EQ13", "category": "IMMUNOLOGY - SEROLOGY", "test": "HEPATITIS B ENVELOPE ANTIGEN (HBeAg)", "price": 1000 },
  { "code": "HQ14", "category": "IMMUNOLOGY - SEROLOGY", "test": "HEPATITIS B SURFACE ANTIBODY TOTAL (ANTI - HBs)", "price": 1000 },
  { "code": "HQ56", "category": "IMMUNOLOGY - SEROLOGY", "test": "HEPATITIS B SURFACE ANTIGEN - ECLIA", "price": 1200 },
  { "code": "8007", "category": "IMMUNOLOGY - SEROLOGY", "test": "HEPATITIS B SURFACE ANTIGEN - RAPID CARD", "price": 500 },
  { "code": "HQ22", "category": "IMMUNOLOGY - SEROLOGY", "test": "Hepatitis C Virus (HCV Antibody) ELISA", "price": 1000 },
  { "code": "HQ55", "category": "IMMUNOLOGY - SEROLOGY", "test": "HEPATITIS C VIRUS (HCV)- ECLIA", "price": 1000 },
  { "code": "HQ49", "category": "IMMUNOLOGY - SEROLOGY", "test": "HEPATITIS C VIRUS (RAPID)", "price": 500 },
  { "code": "6551", "category": "IMMUNOLOGY - SEROLOGY", "test": "HEPATITIS C VIRUS ANTIBODY - RAPID CARD", "price": 500 },
  { "code": "HQ23", "category": "IMMUNOLOGY - SEROLOGY", "test": "Hepatitis Core Antibodies IgM (Anti HBc-IgM)", "price": 1000 },
  { "code": "HQ24", "category": "IMMUNOLOGY - SEROLOGY", "test": "Hepatitis Core Antibody Total (HBcAb-Total)", "price": 1000 },
  { "code": "HQ25", "category": "IMMUNOLOGY - SEROLOGY", "test": "Hepatitis E Virus-IgG (HEV IgG)", "price": 1000 },
  { "code": "HQ26", "category": "IMMUNOLOGY - SEROLOGY", "test": "Hepatitis E Virus-IgM (HEV IgM)", "price": 1000 },
  { "code": "HQ29", "category": "IMMUNOLOGY - SEROLOGY", "test": "HERPES SIMPLEX VIRUS -1 IgG", "price": 500 },
  { "code": "HQ27", "category": "IMMUNOLOGY - SEROLOGY", "test": "HERPES SIMPLEX VIRUS 1 & 2 IgG", "price": 500 },
  { "code": "HQ28", "category": "IMMUNOLOGY - SEROLOGY", "test": "HERPES SIMPLEX VIRUS 1 & 2 IgM", "price": 500 },
  { "code": "HQ30", "category": "IMMUNOLOGY - SEROLOGY", "test": "HERPES SIMPLEX VIRUS 1 IgM", "price": 500 },
  { "code": "HQ28", "category": "IMMUNOLOGY - SEROLOGY", "test": "HERPES SIMPLEX VIRUS 1&2 IGM", "price": 500 },
  { "code": "HQ31", "category": "IMMUNOLOGY - SEROLOGY", "test": "HERPES SIMPLEX VIRUS 2 IgG", "price": 500 },
  { "code": "HQ32", "category": "IMMUNOLOGY - SEROLOGY", "test": "HERPES SIMPLEX VIRUS 2 IgM", "price": 500 },
  { "code": "HQ34", "category": "IMMUNOLOGY - SEROLOGY", "test": "HIGH SENSITIVITY CRP (hs CRP)", "price": 650 },
  { "code": "HQ36", "category": "IMMUNOLOGY - SEROLOGY", "test": "HIV 1 & 2 Antibody (ELISA)", "price": 700 },
  { "code": "HQ36R", "category": "IMMUNOLOGY - SEROLOGY", "test": "HIV 1&2 ANTIBODY RAPID", "price": 500 },
  { "code": "HQ54", "category": "IMMUNOLOGY - SEROLOGY", "test": "HIV 1&2 ANTIBODY-ECLIA", "price": 1000 },
  { "code": "IQ03", "category": "IMMUNOLOGY - SEROLOGY", "test": "IMMUNOGLOBULIN A (IgA)", "price": 1000 },
  { "code": "IQ04", "category": "IMMUNOLOGY - SEROLOGY", "test": "IMMUNOGLOBULIN G (IgG)", "price": 1000 },
  { "code": "7883", "category": "IMMUNOLOGY - SEROLOGY", "test": "IMMUNOGLOBULIN IgE SERUM", "price": 1000 },
  { "code": "IQ05", "category": "IMMUNOLOGY - SEROLOGY", "test": "IMMUNOGLOBULIN M (IgM)", "price": 1000 },
  { "code": "IQ21", "category": "IMMUNOLOGY - SEROLOGY", "test": "INHIBIN A", "price": 1200 },
  { "code": "IQ22", "category": "IMMUNOLOGY - SEROLOGY", "test": "INHIBIN B", "price": 1200 },
  { "code": "7453", "category": "IMMUNOLOGY - SEROLOGY", "test": "INSULIN FREE", "price": 900 },
  { "code": "2762", "category": "IMMUNOLOGY - SEROLOGY", "test": "MANTOUX TEST", "price": 150 },
  { "code": "8496", "category": "IMMUNOLOGY - SEROLOGY", "test": "NIPT", "price": 9000 },
  { "code": "PQ21", "category": "IMMUNOLOGY - SEROLOGY", "test": "PROTEIN - C", "price": 2200 },
  { "code": "PQ25", "category": "IMMUNOLOGY - SEROLOGY", "test": "PROTEIN - S", "price": 2200 },
  { "code": "RQ10", "category": "IMMUNOLOGY - SEROLOGY", "test": "RUBELLA - IgG", "price": 500 },
  { "code": "6421", "category": "IMMUNOLOGY - SEROLOGY", "test": "RUBELLA - IgG & IgM", "price": 900 },
  { "code": "RQ11", "category": "IMMUNOLOGY - SEROLOGY", "test": "RUBELLA - IgM", "price": 500 },
  { "code": "TQ01", "category": "IMMUNOLOGY - SEROLOGY", "test": "TB GOLD QUANTIFERON", "price": 2400 },
  { "code": "TQ11", "category": "IMMUNOLOGY - SEROLOGY", "test": "TISSUE TRANSGLUTAMINASE ANTIBODY -IgG", "price": 1000 },
  { "code": "TQ10", "category": "IMMUNOLOGY - SEROLOGY", "test": "TISSUE TRANSGLUTAMINASE ANTIBODY- IGA (TTG-IGA)", "price": 1000 },
  { "code": "TQ12", "category": "IMMUNOLOGY - SEROLOGY", "test": "TISSUE TRANSGLUTAMINASE ANTIBODY-IgM", "price": 1000 },
  { "code": "AQT10", "category": "IMMUNOLOGY - SEROLOGY", "test": "TORCH 10 PROFILE (IgG & IgM)", "price": 2200 },
  { "code": "AQT15", "category": "IMMUNOLOGY - SEROLOGY", "test": "TORCH 4 PROFILE IgM", "price": 1000 },
  { "code": "AQT13", "category": "IMMUNOLOGY - SEROLOGY", "test": "TORCH 8 PROFILE (IgG & IGM)", "price": 1800 },
  { "code": "AQT14", "category": "IMMUNOLOGY - SEROLOGY", "test": "TORCH PROFILE 4 IgG", "price": 1000 },
  { "code": "AQT12", "category": "IMMUNOLOGY - SEROLOGY", "test": "TORCH PROFILE 5 IgM", "price": 1200 },
  { "code": "AQT11", "category": "IMMUNOLOGY - SEROLOGY", "test": "TORCH PROFILE 5 IgG", "price": 1200 },
  { "code": "TQ15", "category": "IMMUNOLOGY - SEROLOGY", "test": "TOTAL IgE", "price": 500 },
  { "code": "TQ16", "category": "IMMUNOLOGY - SEROLOGY", "test": "TOXOPLASMA IgG", "price": 500 },
  { "code": "TQ17", "category": "IMMUNOLOGY - SEROLOGY", "test": "TOXOPLASMA IgM", "price": 500 },
  { "code": "TQ20", "category": "IMMUNOLOGY - SEROLOGY", "test": "TREPONEMA PALLIDUM HAMAGGLUTINATION( TPHA)", "price": 600 },
  { "code": "4101", "category": "IMMUNOLOGY - SEROLOGY", "test": "TYPHI DOT- RAPID IgG & IgM", "price": 1000 },
  { "code": "VQ02", "category": "IMMUNOLOGY - SEROLOGY", "test": "VARICELLA ZOSTER - IgG", "price": 1400 },
  { "code": "VQ03", "category": "IMMUNOLOGY - SEROLOGY", "test": "VARICELLA ZOSTER - IgM", "price": 1400 },
  { "code": "4234", "category": "IMMUNOLOGY - SEROLOGY", "test": "VDRL SYPHILIS TEST (Rapid Test)", "price": 300 },
  { "code": "6301", "category": "IMMUNOLOGY - SEROLOGY", "test": "VDRL TITRE", "price": 600 },
  { "code": "8523", "category": "IMMUNOLOGY - SEROLOGY", "test": "VIRAL MARKER ELISA (HHH)", "price": 2000 },
  { "code": "8528E", "category": "IMMUNOLOGY - SEROLOGY", "test": "VIRAL MARKER RAPID CARD", "price": 1200 },
  { "code": "WQ03", "category": "IMMUNOLOGY - SEROLOGY", "test": "WIDAL TEST (SLIDE METHOD)", "price": 150 },
  { "code": "WQ04", "category": "IMMUNOLOGY - SEROLOGY", "test": "WIDAL TEST (TUBE METHOD)", "price": 350 },
  { "code": "0010", "category": "LAB PACKAGE", "test": "ANC PROFILE", "price": 1500 },
  { "code": "8521", "category": "LAB PACKAGE", "test": "APLA PROFILE A", "price": 2500 },
  { "code": "8522", "category": "LAB PACKAGE", "test": "APLA PROFILE B", "price": 4000 },
  { "code": "8500", "category": "LAB PACKAGE", "test": "ARTHRITIS BASIC PANEL", "price": 1500 },
  { "code": "8358", "category": "LAB PACKAGE", "test": "ARTHRITIS PROFILE", "price": 4000 },
  { "code": "8345", "category": "LAB PACKAGE", "test": "CARDIAK RISK MARKER PROFILE", "price": 1500 },
  { "code": "8356", "category": "LAB PACKAGE", "test": "CBC MP WIDAL", "price": 650 },
  { "code": "DP15", "category": "LAB PACKAGE", "test": "Coagulation Profile", "price": 1000 },
  { "code": "8499", "category": "LAB PACKAGE", "test": "COMPLETE BLOOD COUNT", "price": 350 },
  { "code": "8507", "category": "LAB PACKAGE", "test": "DENGUE IGG ELISA", "price": 800 },
  { "code": "8349", "category": "LAB PACKAGE", "test": "DENGUE SEROLOGY IGG & IGM", "price": 1400 },
  { "code": "8305", "category": "LAB PACKAGE", "test": "FEVER PROFILE-A", "price": 1000 },
  { "code": "8352", "category": "LAB PACKAGE", "test": "FEVER PROFILE-B", "price": 2000 },
  { "code": "8357", "category": "LAB PACKAGE", "test": "FEVER PROFILE-C", "price": 3000 },
  { "code": "8473", "category": "LAB PACKAGE", "test": "FEVER PROFILE-D", "price": 3000 },
  { "code": "1998", "category": "LAB PACKAGE", "test": "FREE TFT", "price": 650 },
  { "code": "GQ08", "category": "LAB PACKAGE", "test": "GLUCOSE F/PP", "price": 120 },
  { "code": "8380", "category": "LAB PACKAGE", "test": "GYM PROFILE", "price": 3000 },
  { "code": "AQH10", "category": "LAB PACKAGE", "test": "HEMOGRAM", "price": 500 },
  { "code": "8452", "category": "LAB PACKAGE", "test": "HEPATITIS PANEL", "price": 5000 },
  { "code": "AQK2", "category": "LAB PACKAGE", "test": "Kidney Profile", "price": 500 },
  { "code": "DP40", "category": "LAB PACKAGE", "test": "Liver Function Test", "price": 500 },
  { "code": "8375", "category": "LAB PACKAGE", "test": "MYELOMA PANEL", "price": 10000 },
  { "code": "8347", "category": "LAB PACKAGE", "test": "PANCREAS PROFILE", "price": 1200 },
  { "code": "8374", "category": "LAB PACKAGE", "test": "PCOD PROFILE", "price": 2400 },
  { "code": "8431", "category": "LAB PACKAGE", "test": "PCOD PROFILE MAX", "price": 4000 },
  { "code": "8351", "category": "LAB PACKAGE", "test": "TYPHI DOT IGG & IGM", "price": 500 },
  { "code": "8438", "category": "LAB PACKAGE", "test": "UWC 1.0", "price": 1000 },
  { "code": "8444", "category": "LAB PACKAGE", "test": "UWC 1.1", "price": 1500 },
  { "code": "8446", "category": "LAB PACKAGE", "test": "UWC 1.3", "price": 3000 },
  { "code": "8465", "category": "LAB PACKAGE", "test": "UWC 1.5", "price": 4000 },
  { "code": "8459", "category": "LAB PACKAGE", "test": "UWC FAMILY PACKAGE-C", "price": 6000 },
  { "code": "8445", "category": "LAB PACKAGE", "test": "UWC-1.2", "price": 2000 },
  { "code": "8447", "category": "LAB PACKAGE", "test": "UWC-1.4", "price": 4000 },
  { "code": "8350", "category": "LAB PACKAGE", "test": "UWC-D PROFILE", "price": 5000 },
  { "code": "5052", "category": "LAB PACKAGE", "test": "VIRAL MARKERS", "price": 1200 },
  { "code": "4736", "category": "MICROBIOLOGY", "test": "ACID FAST BACILLI STAIN (AFB ZN)", "price": 200 },
  { "code": "CQ118", "category": "MICROBIOLOGY", "test": "CATHETER TIP CULTURE & SENSITIVITY AEROBIC", "price": 1000 },
  { "code": "CQ64", "category": "MICROBIOLOGY", "test": "CULTURE & SENSITIVITY AEROBIC BLOOD", "price": 1000 },
  { "code": "CQ65", "category": "MICROBIOLOGY", "test": "CULTURE & SENSITIVITY AEROBIC BODY FLUIDS", "price": 1000 },
  { "code": "CQ66", "category": "MICROBIOLOGY", "test": "CULTURE & SENSITIVITY AEROBIC CSF", "price": 1000 },
  { "code": "CQ67", "category": "MICROBIOLOGY", "test": "CULTURE & SENSITIVITY AEROBIC EAR SWAB", "price": 1000 },
  { "code": "CQ68", "category": "MICROBIOLOGY", "test": "CULTURE & SENSITIVITY AEROBIC EYE SWAB", "price": 1000 },
  { "code": "CQ69", "category": "MICROBIOLOGY", "test": "CULTURE & SENSITIVITY AEROBIC MISCELLANEOUS", "price": 1000 },
  { "code": "CQ70", "category": "MICROBIOLOGY", "test": "CULTURE & SENSITIVITY AEROBIC NASAL SWAB", "price": 1000 },
  { "code": "CQ71", "category": "MICROBIOLOGY", "test": "CULTURE & SENSITIVITY AEROBIC PUS", "price": 1000 },
  { "code": "CQ72", "category": "MICROBIOLOGY", "test": "CULTURE & SENSITIVITY AEROBIC SEMEN", "price": 1000 },
  { "code": "CQ74", "category": "MICROBIOLOGY", "test": "CULTURE & SENSITIVITY AEROBIC THROAT SWAB", "price": 1000 },
  { "code": "CQ75", "category": "MICROBIOLOGY", "test": "CULTURE & SENSITIVITY AEROBIC VAGINAL SWAB", "price": 1000 },
  { "code": "CQ73", "category": "MICROBIOLOGY", "test": "CULTURE AND SENSITIVITY AEROBIC SPUTUM", "price": 1000 },
  { "code": "CQ76", "category": "MICROBIOLOGY", "test": "CULTURE AND SENSITIVITY AEROBIC STOOL", "price": 1000 },
  { "code": "8382", "category": "MICROBIOLOGY", "test": "H1N1 (Swine flu) Qualitative RT PCR", "price": 5000 },
  { "code": "CQ110", "category": "MICROBIOLOGY", "test": "PERICARDIAL FLUID CULTURE & SENSITIVITY AEROBIC", "price": 1000 },
  { "code": "CQ111", "category": "MICROBIOLOGY", "test": "PERITONIAL FLUID CULTURE & SENSITIVITY AEROBIC", "price": 1000 },
  { "code": "CQ113", "category": "MICROBIOLOGY", "test": "PLEURAL FLUID CULTURE & SENSITIVITY AEROBIC", "price": 1000 },
  { "code": "4822", "category": "MICROBIOLOGY", "test": "PUS CULTURE & SENSITIVITY", "price": 1000 },
  { "code": "3506", "category": "MICROBIOLOGY", "test": "RECTAL SWAB- CULTURE & SENSITIVITY", "price": 1200 },
  { "code": "SQ06", "category": "MICROBIOLOGY", "test": "SMEAR FOR FUNGAL ELEMENTS", "price": 500 },
  { "code": "AFB", "category": "MICROBIOLOGY", "test": "SPUTUM", "price": 150 },
  { "code": "3771", "category": "MICROBIOLOGY", "test": "SPUTUM CULTURE & SENSITIVITY", "price": 500 },
  { "code": "3792", "category": "MICROBIOLOGY", "test": "STOOL CULTURE & SENSITIVITY", "price": 500 },
  { "code": "CQ19", "category": "MICROBIOLOGY", "test": "SWAB CULTURE", "price": 1000 },
  { "code": "CQ62", "category": "MICROBIOLOGY", "test": "URINE CULTURE & SENSITIVITY Aerobic Bacteria Urine", "price": 500 },
  { "code": "ZQ01", "category": "MICROBIOLOGY", "test": "ZEIL NIELSEN STAIN (AFB STAIN)", "price": 100 },
  { "code": "SQ31", "category": "MOLECULAR MICROBIOLOGY", "test": "SARS COVID19 (RT-PCR)", "price": 500 },
  { "code": "8371", "category": "MOLECULAR PATHOLOGY", "test": "CB NAAT", "price": 4500 },
  { "code": "7625", "category": "MOLECULAR PATHOLOGY", "test": "HEPATITIS E VIRUS (HEV) RNA DETECTION BY PCR", "price": 4500 },
  { "code": "HQ17", "category": "MOLECULAR PATHOLOGY", "test": "Hepatitis B Viral Load (HBV DNA)", "price": 5000 },
  { "code": "HQ15", "category": "MOLECULAR PATHOLOGY", "test": "HEPATITIS B VIRUS GENOTYPE+VIRAL LOAD", "price": 9000 },
  { "code": "HQ18", "category": "MOLECULAR PATHOLOGY", "test": "Hepatitis B Virus-Qualitative", "price": 3000 },
  { "code": "HQ21", "category": "MOLECULAR PATHOLOGY", "test": "HEPATITIS C VIRUS GENOTYPING+VIRAL LOAD", "price": 9000 },
  { "code": "HQ20", "category": "MOLECULAR PATHOLOGY", "test": "Hepatitis C Virus RNA Quantitative PCR (HCV VIRAL LOAD)", "price": 4500 },
  { "code": "HQ19", "category": "MOLECULAR PATHOLOGY", "test": "Hepatitis C Virus-Qualitative", "price": 3000 },
  { "code": "HQ33", "category": "MOLECULAR PATHOLOGY", "test": "HERPES SIMPLEX VIRUS 1 & 2 DNA", "price": 3000 },
  { "code": "6818", "category": "MOLECULAR PATHOLOGY", "test": "Herpes Simplex Virus Type 1 & Type 2 (HSV 1 & 2) Qualitative PCR", "price": 1500 },
  { "code": "HQ39", "category": "MOLECULAR PATHOLOGY", "test": "HIV-1 & 2 RNA Qualitative PCR", "price": 4500 },
  { "code": "HQ59", "category": "MOLECULAR PATHOLOGY", "test": "HIV-2 RNA VIRAL LOAD (QUANTITATIVE) PCR", "price": 4500 },
  { "code": "HQ35", "category": "MOLECULAR PATHOLOGY", "test": "HIV-I & II RNA QUANTITATIVE (Viral Load)", "price": 4500 },
  { "code": "1023", "category": "MOLECULAR PATHOLOGY", "test": "HLA -B51/B5 BY REAL TIME PCR", "price": 7000 },
  { "code": "HQ40", "category": "MOLECULAR PATHOLOGY", "test": "HLA B-27 Flowcytometry", "price": 600 },
  { "code": "HQ44", "category": "MOLECULAR PATHOLOGY", "test": "HLA B27 QUALITATIVE PCR", "price": 2000 },
  { "code": "8135", "category": "MOLECULAR PATHOLOGY", "test": "Influenza A Virus subtype H1N1 (A/H1N1)", "price": 5000 },
  { "code": "8449", "category": "MOLECULAR PATHOLOGY", "test": "SERUM ELECTROFORCES", "price": 350 }
  
];

  // The search query
  searchQuery: string = '';

  constructor() { }

  ngOnInit(): void {
  }

  // Method to filter the list based on the search query
  filteredList() {
    return this.list.filter(item =>
      item.code.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
      item.category.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
      item.test.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  }
}
