import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';  // <-- Add this import
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { HomeComponent } from './components/pages/home/home.component';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { FunfactsComponent } from './components/common/funfacts/funfacts.component';


import { ErrorComponent } from './components/pages/error/error.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';


import { ContactComponent } from './components/pages/contact/contact.component';
import { FranchiseComponent } from './components/pages/franchise/franchise.component';
import { AProfileComponent } from './components/pages/a-profile/a-profile.component';
import { BProfileComponent } from './components/pages/b-profile/b-profile.component';
import { CProfileComponent } from './components/pages/c-profile/c-profile.component';
import { DProfileComponent } from './components/pages/d-profile/d-profile.component';
import { BookNowComponent } from './components/pages/book-now/book-now.component';
import { ForADSAComponent } from './components/pages/for-a-dsa/for-a-dsa.component';
import { BusinessPartnerComponent } from './components/pages/business-partner/business-partner.component';
import { LookingForCorporateTieUpComponent } from './components/pages/looking-for-corporate-tie-up/looking-for-corporate-tie-up.component';
import { AnyOtherQueryComponent } from './components/pages/any-other-query/any-other-query.component';
import { FeedBackComponent } from './components/pages/feed-back/feed-back.component';
import { LoginComponent } from './components/pages/login/login.component';
import { CutomerLoginComponent } from './components/cutomer-login/cutomer-login.component';

// New One
import { ReactiveFormsModule } from '@angular/forms';
import { ContactFormComponent } from './components/common/contact-form/contact-form.component';

import { HttpClientModule } from '@angular/common/http';
import { AlertMsgComponent } from './components/common/alert-msg/alert-msg.component';

import { TestListComponent } from './test-list/test-list.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';







@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    PreloaderComponent,
    FooterComponent,
    HomeComponent,
    AboutUsComponent,
    PartnerComponent,
    FunfactsComponent,
  
    ErrorComponent,
    ComingSoonComponent,

   
    ContactComponent,
    FranchiseComponent,
    AProfileComponent,
    BProfileComponent,
    CProfileComponent,
    DProfileComponent,
    BookNowComponent,
    ForADSAComponent,
    BusinessPartnerComponent,
    LookingForCorporateTieUpComponent,
    AnyOtherQueryComponent,
    FeedBackComponent,
    LoginComponent,
    CutomerLoginComponent,
    // New One
    ContactFormComponent,
    AlertMsgComponent,

    TestListComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,

  

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    // New One
    ReactiveFormsModule,
    FormsModule,  // <-- Add FormsModule here
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
