<div class="page-title-area">
  <div class="container">
      <div class="page-title-content">
          <h2>Test List</h2>
          <ul>
              <li><a routerLink="/">Home</a></li>
              <li>Test List</li>
          </ul>
      </div>
  </div>
</div>

<section class="shop-area ptb-120">
  <div class="container">
      <div class="row">
          <div class="col-lg-12 col-md-12">
              <div class="woocommerce-topbar">
                  <div class="row align-items-center">
                      <div class="col-lg-8 col-md-7">
                         
                      </div>

                      <div class="col-lg-4 col-md-5">
                          <input class="form-control mb-4" id="myInput" type="text" [(ngModel)]="searchQuery" placeholder="Search..." />
                      </div>
                  </div>

                  <div class="row align-items-center">
                      <div class="col-lg-12 col-md-12">
                        <div class="table-responsive">
                          <table class="table">
                            <thead>
                              <tr>  
                                <th>Testcode</th>
                                <th>Name</th>
                                <th>ItemName</th>
                                <th>Rate</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let item of filteredList()">
                                <td>{{ item.code }}</td>
                                <td>{{ item.category }}</td>
                                <td>{{ item.test }}</td>
                                <td>{{ item.price }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        
                      </div>
                  </div>




              </div>


          </div>
      </div>
  </div>
  
</section>